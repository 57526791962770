import { ContentWrapper } from "../ContentWrapper"
import { SectionHeading } from "./SectionHeading"
import { data } from "./data"
import { Box, Heading, Text } from "rebass/styled-components"
import { StorymapContentWrapper } from "../storymap/Storymap"
import * as React from "react"
import { Paper } from "./About"
import styled from "styled-components"

export const MissionStatement = () => (
  <StorymapContentWrapper>
    <ContentWrapper maxWidth={"1290px"}>
      <Paper pt={"120px"} pb={["0px", "120px"]} px={[0, 4, 4, "120px"]}>
        <SectionHeading>{data.missionStatement.title}</SectionHeading>
        <ContentWrapper maxWidth={"850px"}>
          <Heading
            fontSize={["32px", "48px", "48px", "64px"]}
            fontWeight={500}
            my={"67px"}
            maxWidth={["80%", "80%", "80%", "850px"]}
            fontFamily={"Arnhem"}
          >
            {data.missionStatement.heading}
          </Heading>

          <TextBox pl={[0, 5, 5, "110px"]}>
            {data.missionStatement.paragraphs.map((p, i) => (
              <Text
                key={i}
                mb={[4]}
                mr={[0, 5]}
                style={{
                  fontFamily: "Gentona-Light",
                  fontSize: "20px",
                  lineHeight: "32px",
                }}
              >
                {p}
              </Text>
            ))}
          </TextBox>
        </ContentWrapper>
      </Paper>
    </ContentWrapper>
  </StorymapContentWrapper>
)

const TextBox = styled(Box)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    border-left: 4px solid #21cd9c;
  }
`
