import * as React from "react"
import { BoxProps, Heading } from "rebass/styled-components"

export const SectionHeading = ({
  children,
  ...props
}: {
  children: React.ReactNode
} & BoxProps) => (
  <Heading fontSize="32px" {...props}>
    {children}
  </Heading>
)
