import * as React from "react"
import { Hero } from "./Hero"
import { Box } from "rebass/styled-components"
import styled from "styled-components"
import { Leadership } from "./Leadership"
import { MissionStatement } from "./MissionStatement"
import { OtherTeamMembers } from "./OtherTeamMembers"
import { Partners } from "./Partners"
import { Funders } from "./Funders"
import { Contact } from "./Contact"

export const About = () => {
  return (
    <>
      <Hero />

      <MissionStatement />

      <Leadership />

      <OtherTeamMembers />

      <Partners />

      {/*<Funders />*/}

      <Contact />
    </>
  )
}
export const Paper = styled(Box)`
  background-color: #f8fbf6;
  overflow: hidden;
`
