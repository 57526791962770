import * as React from "react"

import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { theme } from "../../theme"

export function HeroImage() {
  const data = useStaticQuery(graphql`
    query {
      smallImage: file(relativePath: { eq: "about/hero-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tabletImage: file(relativePath: { eq: "about/hero-tablet.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 484)
        }
      }
      largeImage: file(relativePath: { eq: "about/hero-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 664)
        }
      }
    }
  `)

  const images = withArtDirection(getImage(data.smallImage), [
    {
      media: `(min-width: ${theme.breakpoints[2]})`,
      image: getImage(data.largeImage),
    },
    {
      media: `(min-width: ${theme.breakpoints[1]})`,
      image: getImage(data.tabletImage),
    },
  ])

  return (
    <GatsbyImage image={images} alt={"Johns Hopkins Building Hall Entrance"} />
  )
}
