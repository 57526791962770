import * as React from "react"
import { Box, Flex, Text, Link } from "rebass/styled-components"
import { ContentWrapper } from "../ContentWrapper"
import { data } from "./data"
import { SectionHeading } from "./SectionHeading"
import { theme } from "../../theme"
import { Grid } from "./Grid"

export const Partners = () => {
  return (
    <Flex bg={theme.colors.bgSuperLight}>
      <ContentWrapper maxWidth="1072px" pt={["100px"]}>
        <SectionHeading mb={[84]}>{data.partners.title}</SectionHeading>
        <Grid mx={[0, 0, 0, "-70px"]}>
          {data.partners.items.map(item => (
            <Link
              key={item.url}
              href={item.url}
              target={"_blank"}
              style={{ color: theme.colors.link }}
            >
              <Box key={item.name} pb={4}>
                <Text
                  my={[4]}
                  fontFamily="Arnhem"
                  fontStyle="normal"
                  fontWeight="300"
                  fontSize="24px"
                  lineHeight="138%"
                >
                  {item.name}
                </Text>
              </Box>
            </Link>
          ))}
        </Grid>
      </ContentWrapper>
    </Flex>
  )
}
