import * as React from "react"
import Layout from "../components/common/Layout"
import { About } from "../components/about/About"
import SEO from "../components/seo"

const App = () => {
  return (
    <Layout>
      <SEO title="About" />
      <About />
    </Layout>
  )
}

export default App
