import * as React from "react"
import { Flex, Button, Text } from "rebass/styled-components"
import { SectionHeading } from "./SectionHeading"
import { data } from "./data"
import { theme } from "../../theme"

export const Contact = () => {
  return (
    <Flex
      bg={theme.colors.body}
      pt={["90px"]}
      pb={["105px"]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <SectionHeading color="white">{data.contact.title}</SectionHeading>
      <Text mt={[4]} mb={[5]} color="white">
        {data.contact.description}
      </Text>
      <Button
        variant="underline"
        as={"a"}
        href={"mailto:" + data.contact.email}
        color="#54DD8B"
        style={{ borderColor: "#54DD8B" }}
      >
        {data.contact.email}
      </Button>
    </Flex>
  )
}
