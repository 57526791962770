import * as React from "react"
import { Box, Flex, Heading, Text } from "rebass/styled-components"
import { theme } from "../../theme"
import { ContentWrapper } from "../ContentWrapper"
import { FancyImage } from "../common/FancyImage"
import { StyledText } from "../common/StyledText"
import { data } from "./data"
import { HeroImage } from "./HeroImage"
import styled from "styled-components"

export const Hero = () => {
  return (
    <Flex bg={theme.colors.bgDark}>
      <ContentWrapper style={{ position: "relative" }} maxWidth={1152}>
        <Flex flexWrap="wrap">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width={[1, 1, 3 / 5]}
            pt={"120px"}
            pb={"60px"}
          >
            <Box pl={[0, 0, 5]} style={{ zIndex: 1 }}>
              <Heading
                fontSize={["32px", "48px", "48px", "64px"]}
                fontWeight={500}
                mb={3}
                color={theme.colors.headingBgDark}
                fontFamily={"Arnhem"}
              >
                <StyledText
                  text={data.hero.heading}
                  styledFragment={data.hero.headingStyled}
                />
              </Heading>

              <Text
                color={"white"}
                opacity={0.78}
                mb={4}
                fontSize={"20px"}
                lineHeight={"30px"}
              >
                {data.hero.subheading}
              </Text>
            </Box>
          </Flex>

          <Box width={[1, 1, 2 / 5]} style={{ position: "relative" }}>
            <ImageWrapper>
              <FancyImage
                imageElement={<HeroImage />}
                circleStyle={{ bottom: 5 }}
                circleColor={"#A6192E"}
              />
            </ImageWrapper>
          </Box>
        </Flex>
      </ContentWrapper>
    </Flex>
  )
}
const ImageWrapper = styled.div`
  min-height: 150px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 200px;
  }

  > * {
    position: absolute;
    top: -60px;

    min-height: 100px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
      margin: 0 40px;
      min-height: 200px;
      top: -35px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      top: 35px;
      min-height: 300px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      margin: 0;
    }
  }
`
