// @ts-ignore
import frank from "../../images/about/frank-curriero.png"
// @ts-ignore
import john from "../../images/about/john-aucott.png"
// @ts-ignore
import nicole from "../../images/about/nicole-baumgarth.png"
import { description } from "../homepage/data"
import { Link } from "rebass/styled-components"
import * as React from "react"
import { theme } from "../../theme"

export const data = {
  hero: {
    heading: "Johns Hopkins Lyme and Tickborne Diseases Dashboard",
    headingStyled: "Tickborne Diseases",
    subheading: description,
  },
  missionStatement: {
    title: "Mission Statement",
    heading: "Harnessing the Power of Geography in Tickborne Diseases Research",
    paragraphs: [
      "Lyme and other tickborne diseases are on the rise. More people are getting sick, ticks are spreading to new" +
        " geographic locations, and new tickborne pathogens and diseases are being discovered. Our mission is to" +
        " address this growing public health threat through a geographic lens. Our interactive mapping dashboard" +
        " brings together disease, environmental, social, and other geographic data and makes them available to visualize and download. Through our dashboard, we aim to track and contextualize the impact of tickborne diseases while also highlighting data limitations and gaps in knowledge.",

      "Our initiative is guided by a comprehensive One Health approach that utilizes multi-sectoral and multi-disciplinary collaboration to understand how the interdependence of humans, animals, and our shared environment impacts tickborne diseases. With this approach, we will advance understanding of the geography of tickborne diseases, improve awareness across multiple stakeholders, and motivate and support future research collaborations and communication.",
    ],
  },
  leadership: {
    title: "Leadership",
    items: [
      {
        name: "Frank C. Curriero, PhD",
        image: frank,
        title: (
          <span>
            Director,{" "}
            <Link
              target="_blank"
              href="https://publichealth.jhu.edu/spatial-science-for-public-health-center"
              color={theme.colors.link}
            >
              Johns Hopkins Spatial Science for Public Health Center
            </Link>
          </span>
        ),
        description:
          "Dr. Curriero is a Professor in the Department of Epidemiology with a joint appointment in the Department of Biostatistics at the Johns Hopkins Bloomberg School of Public Health. Dr. Curriero's research focus is on the development and application of spatial and spatial-temporal statistical methods in the public health sciences. Much of his current work involves translating methods of spatial science involving spatial data, GIS and spatial statistics for building research capacity in wide ranging health research applications.",
      },
      {
        name: "John Aucott, MD",
        image: john,
        title: (
          <span>
            Director,{" "}
            <Link
              target="_blank"
              href="https://www.hopkinslyme.org/"
              color={theme.colors.link}
            >
              Johns Hopkins Medicine Lyme Disease Research Center
            </Link>
          </span>
        ),
        description:
          "Dr. Aucott is an Associate Professor in the Department of Medicine and is the Director of the School of Medicine Lyme Disease Clinical Research Center. Dr. Aucott’s research focus is on the characterization and elucidation of the biologic basis of persistent symptoms in Lyme disease. The landmark SLICE studies have enrolled hundreds of patients with associated biospecimens that are unique in the field. His work includes collaborations on new diagnostic test development, discovery of biomarkers, and imaging tools for measuring persistent disease and developing the framework for future treatment trials.",
      },
      {
        name: "Nicole Baumgarth, DVM, PhD",
        image: nicole,
        title: (
          <span>
            Director,{" "}
            <Link
              target="_blank"
              href="https://publichealth.jhu.edu/giving/get-inspired/johns-hopkins-lyme-and-tickborne-diseases-research-and-education-institute"
              color={theme.colors.link}
            >
              Johns Hopkins Lyme and Tickborne Diseases Research and Education
              Institute
            </Link>
          </span>
        ),
        description:
          "Dr. Baumgarth is a Bloomberg Distinguished Professor in the Department of Molecular Microbiology and Immunology and the inaugural Director of the Lyme and Tickborne Diseases Research and Education Institute. She leads efforts of the Bloomberg School of Public Health to eliminate threats from tickborne diseases, such as Lyme disease. As an immunologist, she studies why some immune responses to infections are successful and others are not.",
      },
    ],
  },
  otherTeamMembers: {
    title: "Supporting Team Members",
    items: [
      {
        name: "Anne E. Corrigan, MS",
        title:
          "Senior Research Data Analyst,\n" +
          "Johns Hopkins Spatial Science for Public Health Center",
      },
      {
        name: "Anton Kvit, ScM",
        title:
          "Senior Research Data Analyst, Johns Hopkins Spatial Science for Public Health Center",
      },
      {
        name: "Alison Rebman, MPH",
        title:
          "Co-Director for Clinical and Epidemiological Research, Johns Hopkins Medicine Lyme Disease Research Center; Instructor, Johns Hopkins Department of Medicine",
      },
      {
        name: "Timothy M. Shields, MA",
        title:
          "Associate Director, Johns Hopkins Spatial Science for Public Health Center",
      },
      {
        name: "Cara Wychgram, MPP",
        title:
          "Research Data Analyst, Johns\n" +
          "Hopkins Spatial Science for Public Health Center",
      },
    ],
  },
  partners: {
    title: "Partners",
    items: [
      {
        url: "https://appsilon.com/",
        name: "Appsilon",
      },
      {
        url: "https://www.clydrn.ca/",
        name: "Canadian Lyme Disease Research Network ",
      },
      {
        url: "https://www.kflaph.ca/",
        name: "KFL&A Public Health",
      },
    ],
  },
  funders: {
    title: "Funders",
    items: [{ name: "David Nolan" }],
  },
  contact: {
    title: "Contact",
    description:
      "If you have any questions or need more information, don’t hesitate to contact us.",
    email: "SSPHC@jhu.edu",
  },
}
