import styled from "styled-components"
import { Flex } from "rebass/styled-components"

export const Grid = styled(Flex)`
  position: relative;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr 1fr;

    > *:not(:nth-child(3n)):not(:last-child) {
      border-right: 1px solid #dfdfdf;
    }

    > * {
      padding: 0 30px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    > * {
      padding: 0 70px;
    }
  }
`
