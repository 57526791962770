import * as React from "react"
import { Box, Flex, Text } from "rebass/styled-components"
import { ContentWrapper } from "../ContentWrapper"
import { data } from "./data"
import { SectionHeading } from "./SectionHeading"
import { Grid } from "./Grid"
import { theme } from "../../theme"

export const OtherTeamMembers = () => {
  return (
    <Flex bg={theme.colors.bgLight}>
      <ContentWrapper maxWidth="1072px" py={["100px"]}>
        <SectionHeading mb={[84]}>{data.otherTeamMembers.title}</SectionHeading>
        <Grid mx={[0, 0, 0, "-70px"]}>
          {data.otherTeamMembers.items.map(item => (
            <Box key={item.name}>
              <Text
                my={[4]}
                fontFamily="Arnhem"
                fontStyle="normal"
                fontWeight="300"
                fontSize="32px"
                lineHeight="118%"
                minHeight={"2.36em"}
              >
                {item.name}
              </Text>
              <Text
                fontFamily="Gentona-Book"
                fontSize="18px"
                lineHeight="24px"
                mb={[4, 4, 0]}
              >
                {item.title}
              </Text>
            </Box>
          ))}
        </Grid>
      </ContentWrapper>
    </Flex>
  )
}
