import * as React from "react"
import { Flex, Text } from "rebass/styled-components"
import { theme } from "../../theme"
import { ContentWrapper } from "../ContentWrapper"
import { data } from "./data"
import styled from "styled-components"
import { SectionHeading } from "./SectionHeading"

export const Leadership = () => {
  return (
    <Flex bg={theme.colors.bgSuperLight}>
      <ContentWrapper maxWidth="1072px" py={["100px"]}>
        <SectionHeading mb={[84]}>{data.leadership.title}</SectionHeading>
        <Grid>
          {data.leadership.items.map(item => (
            <InnerGrid key={item.name}>
              <img
                src={item.image}
                style={{ borderRadius: "0px 0px 150px 0px" }}
              />
              <Text
                my={[4]}
                fontFamily="Arnhem"
                fontStyle="normal"
                fontWeight="300"
                fontSize="32px"
                lineHeight="118%"
              >
                {item.name}
              </Text>
              <Text
                fontFamily="Gentona-Book"
                fontSize="18px"
                lineHeight="24px"
                mb={[4, 4, 0]}
              >
                {item.title}
              </Text>
              <Text mb={[5]}>{item.description}</Text>
              {/*<Button variant="underline" href={}*/}
            </InnerGrid>
          ))}
        </Grid>
      </ContentWrapper>
    </Flex>
  )
}

const Grid = styled(Flex)`
  position: relative;
  display: grid;

  grid-template-columns: 1fr;
  grid-column-gap: 80px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
  }
`

const InnerGrid = styled(Flex)`
  position: relative;
  display: grid;

  grid-template-columns: 1fr;
  grid-column-gap: 80px;
  grid-template-rows:
    300px
    minmax(100px, auto)
    auto
    minmax(100px, auto);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-rows:
      300px
      130px
      100px
      minmax(100px, auto);
  }
`
